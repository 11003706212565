import styled, { css } from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { media } from '../utils/styles/media.js';
import { BoxGroup } from '../Box/BoxGroup.js';
import { Col } from '../Grid/Col.js';
import { Spacer } from '../Spacer/Spacer.js';

const StyledFeed = styled.div.withConfig({
  componentId: "sg1730__sc-1eeknu6-0"
})(["", "{margin-left:4rem;}"], media.sm);
const StyledFeedSection = styled.div.withConfig({
  componentId: "sg1730__sc-1eeknu6-1"
})([""]);
const StyledFeedSectionList = styled.ul.withConfig({
  componentId: "sg1730__sc-1eeknu6-2"
})(["list-style:none;margin:0;padding:0;"]);
const StyledFeedSectionHeader = styled.div.withConfig({
  componentId: "sg1730__sc-1eeknu6-3"
})(["display:flex;flex-direction:column;justify-content:center;padding-bottom:0.5rem;position:relative;", "{padding-bottom:1rem;&::before{border-left:1px solid ", ";bottom:-1rem;content:'';left:-2.75rem;position:absolute;top:0;.feed-section-empty + ", " &,", ":first-child &,", ":last-child.feed-section-empty &{display:none;}}}"], media.sm, tokens.color.secondary, StyledFeedSection, StyledFeedSection, StyledFeedSection);
const StyledFeedItem = styled.li.withConfig({
  componentId: "sg1730__sc-1eeknu6-4"
})([""]);
const StyledFeedItemRow = styled.article.withConfig({
  componentId: "sg1730__sc-1eeknu6-5"
})(["display:flex;"]);
const StyledFeedItemContent = styled.div.withConfig({
  componentId: "sg1730__sc-1eeknu6-6"
})(["flex:1 1 100%;padding-bottom:", ";position:relative;", "{padding-bottom:", ";}"], props => props.$hasToggle ? tokens.space.xxxl : tokens.space.md, media.sm, props => {
  if (props.$variant === 'compact' && props.$hasToggle && !props.$isOpen) {
    return tokens.space.xxxl;
  } else if (props.$variant === 'compact') {
    return tokens.space.md;
  } else if (props.$hasToggle) {
    return tokens.space['layout-lg'];
  } else {
    return tokens.space.xl;
  }
});
const StyledFeedItemBoxGroup = styled(BoxGroup).withConfig({
  componentId: "sg1730__sc-1eeknu6-7"
})(["position:relative;&::after{content:'';width:6px;position:absolute;left:0;top:0;bottom:0;border-radius:", " 0 0 ", ";background-color:", ";}"], tokens.radius.md, tokens.radius.md, props => props.$status === 'active' ? tokens.color.secondary : 'transparent');
const StyledFeedItemTimeline = styled.div.withConfig({
  componentId: "sg1730__sc-1eeknu6-8"
})(["display:none;", "{display:block;flex:0 0 auto;margin-left:-4rem;position:relative;width:4rem;}", " ", " ", ":last-of-type ", ":last-of-type &::before{border-image:linear-gradient( to top,", " 0,", " 0.5rem,transparent 0.5rem,transparent 1rem,", " 1rem,", " 1.5rem,transparent 1.5rem,transparent 2rem,", " 2rem,", " 2.5rem,transparent 2.5rem,transparent 3rem,", " 3rem,", " 3.5rem,transparent 3.5rem,transparent 4rem,", " 4rem,", " 4.5rem ) 1;bottom:0;border-width:1px 0px 0px 1px;}&::after{border-top-style:", ";border-top-width:1px;border-top-color:", ";content:'';left:1.25rem;position:absolute;right:0;top:", ";}"], media.sm, props => !props.$isLast && css(["&::before{border-left:1px solid ", ";bottom:-1rem;content:'';left:1.25rem;position:absolute;top:1rem;}"], tokens.color.secondary), StyledFeed, StyledFeedSection, StyledFeedItem, tokens.color.secondary, tokens.color.secondary, tokens.color.secondary, tokens.color.secondary, tokens.color.secondary, tokens.color.secondary, tokens.color.secondary, tokens.color.secondary, tokens.color.secondary, tokens.color.secondary, props => props.$onlyHeader ? 'dashed' : 'solid', props => props.$status === 'disabled' || props.$disabled ? tokens.color['neutral-4'] : tokens.color.secondary, props => {
  if (props.$onlyHeader) {
    if (props.$variant === 'compact') {
      return '0.75rem';
    } else {
      return '0.875rem';
    }
  } else if (props.$variant === 'compact') {
    return '1.5rem';
  } else {
    return '1.75rem';
  }
});
const StyledFeedItemIconContainer = styled.div.withConfig({
  componentId: "sg1730__sc-1eeknu6-9"
})(["align-items:center;background-color:", ";border:1px solid ", ";border-radius:", ";color:", ";display:flex;height:", ";width:", ";justify-content:center;position:relative;z-index:1;transform:", ";", " ", " ", ""], tokens.color.white, tokens.color.secondary, tokens.radius.circle, tokens.color.secondary, props => props.$onlyHeader ? '1rem' : '2.5rem', props => props.$onlyHeader ? '1rem' : '2.5rem', props => {
  if (props.$onlyHeader) {
    if (props.$variant === 'compact') {
      return 'translate(0.75rem, 0.25rem)';
    } else {
      return 'translate(0.75rem, 0.375rem)';
    }
  } else if (props.$variant === 'compact') {
    return 'translate(0, 0.25rem)';
  } else {
    return 'translate(0, 0.5rem)';
  }
}, props => (props.$status === 'active' || props.$active) && css(["background-color:", ";color:", ";"], tokens.color.secondary, tokens.color.white), props => (props.$status === 'disabled' || props.$disabled) && css(["background-color:", ";border-color:", ";color:", ";"], tokens.color['neutral-2'], tokens.color['neutral-4'], tokens.color['neutral-6']), props => props.$status === 'waiting' && css(["background-color:", ";border-color:", ";color:", ";"], tokens.color['secondary-lightest'], tokens.color['secondary-lightest'], tokens.color.secondary));
const StyledFeedItemSubitems = styled.ul.withConfig({
  componentId: "sg1730__sc-1eeknu6-10"
})(["list-style:none;margin:0;padding:0;position:relative;", ""], props => props.$hasToggle && css(["&:before{border-left-width:1px;border-left-style:solid;border-left-color:", ";content:'';height:", ";left:0.5rem;position:absolute;top:0;", "{left:1.5rem;}}> *:first-child ", "{padding-top:", ";}"], tokens.color['neutral-4'], () => props.$variant === 'compact' ? tokens.space.xxxl : tokens.space['layout-lg'], media.sm, StyledFeedSubItemContent, () => props.$variant === 'compact' ? tokens.space.xxxl : tokens.space['layout-lg']));
const StyledFeedSubItem = styled.li.withConfig({
  componentId: "sg1730__sc-1eeknu6-11"
})(["display:flex;position:relative;"]);
const StyledFeedSubItemContent = styled.div.withConfig({
  componentId: "sg1730__sc-1eeknu6-12"
})(["flex:1 1 auto;padding-top:", ";", "{padding-top:", ";}"], tokens.space.xs, media.sm, props => props.$variant === 'compact' ? tokens.space.xs : tokens.space.md);

/* The vertical line for sub items */
const StyledFeedSubitemTimeline = styled.div.withConfig({
  componentId: "sg1730__sc-1eeknu6-13"
})(["flex:0 0 auto;position:relative;width:1rem;", "{width:3rem;}", ""], media.sm, props => {
  if (props.$onlyHeader) {
    return css(["&:before{content:none;}"]);
  } else {
    return css(["&:before{border-left-width:1px;border-left-style:solid;border-left-color:", ";bottom:0;content:'';left:0.5rem;position:absolute;top:0;", "{left:1.5rem;}}", " ", ":last-of-type &:before{content:none;}"], tokens.color['neutral-4'], media.sm, StyledFeedItemSubitems, StyledFeedSubItem);
  }
});
const StyledFeedItemButtonContainer = styled.div.withConfig({
  componentId: "sg1730__sc-1eeknu6-14"
})(["display:flex;padding-top:", ";position:absolute;right:0;z-index:1;"], tokens.space.xs);
const StyledFeedHeaderTextContainer = styled(Col).withConfig({
  componentId: "sg1730__sc-1eeknu6-15"
})(["font-size:", ";"], props => props.$variant === 'compact' ? tokens['font-size'].sm : tokens['font-size'].md);
const FeedEventChildrenContainer = styled(Spacer).withConfig({
  componentId: "sg1730__sc-1eeknu6-16"
})(["> :last-child{margin-bottom:0;}"]);

export { FeedEventChildrenContainer, StyledFeed, StyledFeedHeaderTextContainer, StyledFeedItem, StyledFeedItemBoxGroup, StyledFeedItemButtonContainer, StyledFeedItemContent, StyledFeedItemIconContainer, StyledFeedItemRow, StyledFeedItemSubitems, StyledFeedItemTimeline, StyledFeedSection, StyledFeedSectionHeader, StyledFeedSectionList, StyledFeedSubItem, StyledFeedSubItemContent, StyledFeedSubitemTimeline };
